import { environment } from '@dar/environments/environment';
import {
  format,
  formatDistanceToNowStrict,
  isToday,
  isYesterday,
} from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { TFunction } from 'react-i18next';
import {
  dateLocaleConfig,
  dateTimeStringFormat,
  localeMap,
  muiLocales,
  muiPlaceholder,
  region,
} from './configs/date-config';

export const getLocale = () => {
  const currentLanguage = localStorage.getItem('i18nextLng');
  if (currentLanguage === 'ru')
    return {
      locale: localeMap.ru,
      muiLocale: muiLocales.ru,
      muiPlaceholder: muiPlaceholder.RU,
      stringDateTimeFormat: dateTimeStringFormat.RU,
    };
  if (currentLanguage === 'kz')
    return {
      locale: localeMap.kz,
      muiLocale: muiLocales.kz,
      muiPlaceholder: muiPlaceholder.KZ,
      stringDateTimeFormat: dateTimeStringFormat.KZ,
    };
  if (currentLanguage === 'de')
    return {
      locale: localeMap.de,
      muiLocale: muiLocales.de,
      muiPlaceholder: muiPlaceholder.DE,
      stringDateTimeFormat: dateTimeStringFormat.DE,
    };
  return {
    locale: localeMap.en,
    muiLocale: muiLocales.en,
    muiPlaceholder: muiPlaceholder.EN,
    stringDateTimeFormat: dateTimeStringFormat.EN,
  };
};

export const getDateLocale = (region?: region) => {
  const currentRegion = region ? region : getRegion();
  if (currentRegion === 'US') {
    return dateLocaleConfig[currentRegion];
  }
  const locale = getLocale();
  return { ...dateLocaleConfig[currentRegion], ...locale };
};

export const getFormatted = (
  date: Date,
  options?: {
    formatString?: string;
    isDateStringFormat?: boolean;
    isDateTimeFormat?: boolean;
    isFullDateTimeFormat?: boolean;
    isStringDateTimeFormat?: boolean;
    isCurrentYear?: boolean;
    timeOnly?: boolean;
  }
) => {
  const locale = getDateLocale();
  if (
    options?.isCurrentYear &&
    date.getFullYear() === new Date().getFullYear()
  ) {
    return format(
      date,
      options?.formatString || options?.isDateStringFormat
        ? locale.currentYearStringDateFormat
        : locale.dateFormat,
      {
        locale: locale.locale,
      }
    );
  }

  return format(
    date,
    options?.formatString || options?.isDateStringFormat
      ? locale.stringDateFormat
      : options?.isDateTimeFormat
      ? locale.dateTimeFormat
      : options?.isFullDateTimeFormat
      ? locale.fullDateTimeFormat
      : options?.timeOnly
      ? locale.timeFormat
      : options?.isStringDateTimeFormat
      ? locale.stringDateTimeFormat
      : locale.dateFormat,
    {
      locale: locale.locale,
    }
  );
};

export const getAgoTime = (timestamp: number, t: TFunction) => {
  const date = new Date(timestamp * 1000);
  if (isToday(date)) {
    return t('common.today');
  }
  if (isYesterday(date)) {
    return t('common.yesterday');
  }

  return formatDistanceToNowStrict(date, {
    locale: getLocale().locale,
    addSuffix: true,
  });
};

export const getRegion = () => {
  const locale = localStorage.getItem('locale-override');

  return locale ? locale : environment.localeRegion;
};

export const getTimezone = () => {
  const region = getRegion();
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (timezone) {
    return timezone;
  } else {
    if (region === 'EU') {
      return 'Europe/Berlin';
    }

    if (region === 'US') {
      return 'America/New_York';
    }
    return 'Asia/Almaty';
  }
};

export const convertDateToTimestamp = (
  date: Date,
  timeZone = 'Asia/Almaty'
) => {
  const zonedDate = toZonedTime(date, timeZone);

  return Math.floor(zonedDate.getTime() / 1000);
};
