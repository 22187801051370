/// <reference types="@dartech/dms-ui/dist/theme/types" />

import { theme } from '@dartech/dms-ui';
import { AvatarGroupClassKey, AvatarGroupProps, alpha } from '@mui/material';

declare module '@mui/material/styles/overrides' {
  interface ComponentNameToClassKey {
    MuiAvatarGroup: AvatarGroupClassKey;
  }
}

declare module '@mui/material/styles/props' {
  interface ComponentsPropsList {
    MuiAvatarGroup: AvatarGroupProps;
  }
}

// TODO: Update whole primary color in theme
const newPrimaryHover = '#0692D9';
const newPrimaryActive = '#088DD2';
theme.palette = {
  ...theme.palette,
  primary: {
    ...theme.palette.primary,
    main: '#039BE6',
  },
  error: {
    ...theme.palette.error,
    dark: '#D6331F',
  },
  success: {
    ...theme.palette.success,
    dark: '#137149',
  },
  info: {
    ...theme.palette.info,
    dark: '#262842',
  },
};

theme.components = {
  MuiCssBaseline: {
    styleOverrides: {
      '@global': {
        '*::-webkit-slider-thumb:hover': {
          background: '#F5F8FA',
        },
        '*::-moz-range-thumb:hover': {
          background: '#F5F8FA',
        },
        '*::-webkit-slider-thumb:active': {
          background: '#E6ECF1',
        },
        '*::-moz-range-thumb:active': {
          background: '#E6ECF1',
        },
        '*, *::before,*::after': {
          boxSizing: 'border-box',
        },
        a: {
          textDecoration: 'none',
          color: 'inherit',
          cursor: 'pointer',
        },
        p: {
          margin: 0,
        },
        '*': {},
        '.public-DraftStyleDefault-ul,.DraftView ul': {
          paddingLeft: '1rem',
          listStyle: 'initial',
        },
        '.public-DraftStyleDefault-ol,.DraftView ol': {
          paddingLeft: '1rem',
          listStyle: 'decimal',
        },
        '.DraftEditor-root': {
          color: '#000',
          lineHeight: '1.5rem',
        },
        'p.blockquote': {
          background: 'transparent',
          borderLeft: '2px solid #1b74b4',
          margin: '0 10px 12px',
          padding: '0.5em 10px',
        },
        'p.blockquote p': {
          display: 'inline',
        },
      },
    },
  },
  MuiFilledInput: {
    styleOverrides: {
      root: {
        minWidth: 'fit-content',
        borderRadius: '10px',
        backgroundColor: 'transparent',
        '&:hover': { backgroundColor: 'transparent' },
        '&.Mui-focused': { backgroundColor: 'transparent' },
      },
      underline: {
        '&:before, &:after, &:hover:before, &:hover:after': {
          borderBottom: 'none',
        },
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        minWidth: '95px !important',
        padding: '11px 12px',
        textTransform: 'none',

        '&.Mui-selected': {
          color: theme.palette.main.permanentPrimaryVariant,
          '&:after': {
            backgroundColor: `${theme.palette.main.permanentPrimaryVariant} !important`,
          },
        },
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: {
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
        '&.MuiTableRow-hover:hover': {
          background: '#F0F0F7!important',
        },
      },
    },
  },

  MuiBackdrop: {
    styleOverrides: {
      root: {
        background: '#283A55 !important',
        opacity: '0.24 !important',
        transform: 'matrix(-1, 0, 0, 1, 0, 0)',
        //backgroundColor: 'rgba(28, 55, 76, 0.5)',
        // backdropFilter: 'blur(4px)',
        zIndex: 1200,
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        zIndex: 1210,
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        '.MuiAccordion-root': {
          boxShadow: 'none',
        },
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      /* Styles applied to the root element. */
      root: {
        padding: 8,
        color: theme.palette.grey[500],
        backgroundColor: 'transparent',
        border: 'none',
        borderColor: `transparent`,
        '& path': {
          fill: theme.palette.grey[500],
        },
        '&$disabled': {
          color: theme.palette.grey[300],
          '& path': {
            fill: theme.palette.grey[300],
          },
        },
        '&$checked': {
          color: theme.palette.primary[200],
          '& path': {
            fill: '#007994 !important',
          },
        },
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        color: '#6D7C8B',
        '&:hover': { backgroundColor: 'rgba(109, 124, 139, 0.1)' },
      },
      colorPrimary: {
        color: '#6D7C8B',
        '&:not(.MuiRadio-root):not(.MuiCheckbox-root):not(.Mui-checked):not(.MuiSwitch-switchBase):not(.MuiAutocomplete-clearIndicator) path':
          {
            fill: '#6D7C8B',
          },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        background: '#fff',
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: '8px',
      },
    },
  },
  MuiAvatar: {
    styleOverrides: {
      root: {
        padding: '0',
      },
      // avatar: {
      //   marginLeft: '-8px',
      //   marginRight: '0',
      // },
    },
  },
  MuiSelect: {
    styleOverrides: {
      root: {
        minWidth: '58px',
        minHeight: '40px',
        padding: '10px 8px',
        background: '#fff',

        '& .MuiSelect-select': {
          padding: '0',
        },
      },
      icon: {
        fill: '#6D7C8B',
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        '& > h2': {
          color: '#101F2B',
        },
      },
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        '& > a': {
          whiteSpace: 'break-spaces',
          wordBreak: 'break-word',
        },
        padding: '8px 12px',
      },
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        borderRadius: '10px',
        fontSize: '14px',
        lineHeight: '18px',
      },
      contained: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        '& path': {
          fill: theme.palette.common.white,
        },
        '&:hover': {
          backgroundColor: newPrimaryHover,
          borderColor: newPrimaryHover,
        },
        '&:active': {
          backgroundColor: newPrimaryActive,
          borderColor: newPrimaryActive,
        },
        '&:disabled': {
          backgroundColor: theme.palette.grey[300],
          borderColor: theme.palette.grey[300],
          color: theme.palette.common.white,
        },
      },
      outlined: {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.info.dark,
        border: `1px solid ${alpha(theme.palette.info.dark, 0.12)}`,
        boxShadow: 'none',
        '& path': {
          fill: theme.palette.info.dark,
        },
        '&:hover': {
          backgroundColor: 'rgba(29, 43, 54, 0.08)',
          borderColor: alpha(theme.palette.info.dark, 0.12),
        },
        '&:active': {
          backgroundColor: 'rgba(29, 43, 54, 0.08)',
          borderColor: alpha(theme.palette.info.dark, 0.12),
        },
        '&:disabled': {
          backgroundColor: theme.palette.secondary[300],
          color: theme.palette.common.white,
          borderColor: theme.palette.secondary[300],

          '& path': {
            fill: '#ACB6BF',
          },
        },
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        color: '#2F80ED',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
          color: theme.palette.info.dark,
        },
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        '&:hover': {
          backgroundColor: '#F5F8FA',
        },
      },
    },
  },
};

export default theme;
