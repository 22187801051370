import { deDE, enUS, eu, kzKZ, ruRU } from '@mui/x-date-pickers/locales';
import { Locale } from 'date-fns';
import { kk, ru, enUS as US, enIE, de } from 'date-fns/locale';

export const localeMap = {
  en: enIE,
  enUs: US,
  kz: kk,
  ru: ru,
  de: de,
};

export const muiLocales = {
  en: eu,
  enUs: enUS,
  kz: kzKZ,
  ru: ruRU,
  de: deDE,
};

export enum muiPlaceholder {
  KZ = 'Күні.Айы.Жылы',
  RU = 'ДД.ММ.ГГГГ',
  EN = 'DD.MM.YYYY',
  US = 'MM/DD/YYYY',
  DE = 'TT.MM.JJJJ',
}

export enum dateTimeStringFormat {
  KZ = "d MMM yyyy HH:MM'-де'",
  RU = "d MMM yyyy 'в' HH:MM",
  EN = "d MMM yyyy 'at' HH:MM",
  US = "MMM d, yyyy 'at' hh:mm a",
  DE = "d MMM yyyy 'um' HH:MM",
}

export type region = 'US' | 'EU' | 'KZ';

export const dateLocaleConfig: Record<
  region | 'Default',
  {
    firstDayOfWeek: number;
    dateFormat: string;
    fullDateFormat: string;
    stringDateFormat: string;
    currentYearStringDateFormat: string;
    timeFormat: string;
    dateTimeFormat: string;
    fullDateTimeFormat: string;
    locale: Locale;
    muiLocale: typeof muiLocales.en;
    muiPlaceholder: muiPlaceholder;
  }
> = {
  Default: {
    firstDayOfWeek: 1,
    dateFormat: 'dd.MM.yyyy',
    fullDateFormat: 'cccc, dd.MM.yyyy',
    stringDateFormat: 'd MMMM YYY',
    currentYearStringDateFormat: 'd MMMM',
    timeFormat: 'HH:mm',
    dateTimeFormat: 'dd.MM.yyyy, HH:mm',
    fullDateTimeFormat: 'cccc, dd.MM.yy, HH:mm',
    locale: localeMap.en,
    muiLocale: muiLocales.en,
    muiPlaceholder: muiPlaceholder.EN,
  },
  US: {
    firstDayOfWeek: 0,
    dateFormat: 'MM/dd/yyyy',
    fullDateFormat: 'cccc, MM/dd/yyyy',
    stringDateFormat: 'MMMM d, YYY',
    currentYearStringDateFormat: 'MMMM d',
    timeFormat: 'hh:mm a',
    dateTimeFormat: 'MM/dd/yyyy, hh:mm a',
    fullDateTimeFormat: 'cccc, MM/dd/yyyy, hh:mm a',
    locale: localeMap.enUs,
    muiLocale: muiLocales.enUs,
    muiPlaceholder: muiPlaceholder.US,
  },
  EU: {
    firstDayOfWeek: 1,
    dateFormat: 'dd.MM.yyyy',
    fullDateFormat: 'cccc, dd.MM.yyyy',
    stringDateFormat: 'd MMMM YYY',
    currentYearStringDateFormat: 'd MMMM',
    timeFormat: 'HH:mm',
    dateTimeFormat: 'dd.MM.yyyy, HH:mm',
    fullDateTimeFormat: 'cccc, dd.MM.yy, HH:mm',
    locale: localeMap.en,
    muiLocale: muiLocales.en,
    muiPlaceholder: muiPlaceholder.EN,
  },
  KZ: {
    firstDayOfWeek: 1,
    dateFormat: 'dd.MM.yyyy',
    fullDateFormat: 'cccc, dd.MM.yyyy',
    stringDateFormat: 'd MMMM YYY',
    currentYearStringDateFormat: 'd MMMM',
    timeFormat: 'HH:mm',
    dateTimeFormat: 'dd.MM.yyyy, HH:mm',
    fullDateTimeFormat: 'cccc, dd.MM.yyyy, HH:mm',
    locale: localeMap.kz,
    muiLocale: muiLocales.kz,
    muiPlaceholder: muiPlaceholder.EN,
  },
};
