import { Image } from './cms';
import { DirectoryEntityDTO } from './user';

export type PostQuickSearchCourseRequest = {
  filter_by: {
    channel_ids?: string[];
    course_ids?: QuickSearchCourseId[];
    course_levels?: string[];
    course_statuses?: string[];
    course_types?: string[];
    languages?: string[];
    skills?: string[];
    subscription_ids?: string[];
    types?: string[];
    prices_exist?: boolean;
    mentor_ids?: string[];
    folder_ids?: string[];
    visible_workspace_ids?: string[];
  };
  page_index?: number;
  page_size?: number;
  search?: string;
  search_by_type?: string;
  sort_by?: {
    field?: SortByCourseFieldType;
    sort_criteria?: 'desc' | 'asc';
  }[];
};

export type QuickSearchCourseId = `course_${string | number}`;

export type SortByCourseFieldType =
  | 'publication_date'
  | 'popularity_weight'
  | 'timemodified';

export interface FeedCourseItem extends FeedBaseItem {
  bonus_courses: BonusCourses[];
  entity_type: FeedEntityTypes.COURSE;
  invite_status: CourseInviteStatus;
  course_info: {
    authors: Authors[];
    id: string;
    coursetype: CourseType;
    fullname: string;
    summary: string;
    skills: Skill[];
    customfields: Record<string, CourseCustomField>;
    startdate: string;
    enddate: number;
    bySubscription?: boolean;
    total_activities: number;
    bonus_courses: number[] | null;
    mentor: CourseMentor[];
    sections?: CourseSection[];
    invite_enddate?: number;
  };
}

export enum FeedEntityTypes {
  COURSE = 'COURSE',
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
  STREAM = 'STREAM',
  AUDIO = 'AUDIO',
}

export enum FeedEntityStatuses {
  PUBLISHED = 'PUBLISHED',
}

export interface FeedBaseItem {
  id: string;
  code: string;
  entity_type: FeedEntityTypes;
  channel_info: {
    id: number;
    description: string;
    name: string;
    logo: Image | null;
    cover: Image | null;
  };
  prices: Price[];
  product_prices: {
    price_id: string;
    price_info: Price;
  }[];
  payment_status: CoursePaymentStatuses;
  product_subscription_ids: string[];
}

type Authors = {
  id: number;
  name: string;
  photo: string;
};

export interface BonusCourses extends FeedBaseItem {
  entity_type: FeedEntityTypes.COURSE;
  invite_status: CourseInviteStatus;
  course_info: {
    authors: Authors[];
    coursetype: CourseType;
    customfields: Record<string, CourseCustomField>;
    enddate: number;
    fullname: string;
    id: string;
    skills: Skill[];
    startdate: string;
    summary: string;
    version: number;
  };
}

export enum CourseType {
  'NORMAL' = 'normal',
  'PROF' = 'prof',
}

export interface Skill {
  name: string;
  id: number;
}

export interface CourseCustomField {
  name: string;
  shortname: string;
  type: string;
  valueraw: any;
  value: string;
}

export interface CourseMentor {
  id: number;
  name: string;
  profession: string;
  description: string;
  picture: string;
}

export type CourseInviteStatus = 'invited' | 'not_invited' | 'blocked';

export enum CoursePaymentStatuses {
  PAID = 'paid',
  NOT_PAID = 'not_paid',
  PENDING = 'pending',
  PAID_SUB = 'paid_sub',
}

export interface Price {
  billingScheme: string;
  currency: string;
  id: string;
  nickname: string;
  trialPeriodDays: number | null;
  type: string;
  unitAmount: number;
  productId: string | null;
  metaData: {
    discount?: string;
    fullPrice?: string;
    billingCycleAnchor: string;
    cancelAt: string;
  };
  recurring: {
    interval: string;
    interval_count: number;
  } | null;
}

export type CourseSection = {
  title: string;
  description: string;
  mods?: NormalizedMod[];
  completionPercentage?: number;
  status?: StatusText;
  section_id: string;
  lesson_count?: number;
  lastModId?: string;
};

export enum StatusText {
  DURING = 'during',
  PASSED = 'passed',
  EXPECTED = 'expected',
}

export type NormalizedMod = {
  completion: boolean;
  id: string;
  mod: string;
  name: string;
  sectionId?: string;
};

export enum StudentProgressStatusKind {
  ALL = '0',
  ACTIVE = '1',
  COMPLETED = '2',
}

export enum StudentProgressFieldOrderKind {
  LAST_NAME = 'lastname',
  LAST_ACCESS = 'lastaccess',
  PROGRESS = 'progress',
  ENROLL_DATE = 'enrolldate',
}

export enum MoodleOrderKind {
  DESC = 'desc',
  ASC = 'asc',
}

export interface GetStudentProgressRequestParams {
  course_id: number;
  section_id: number;
  status: StudentProgressStatusKind;
  page: number;
  per_page: number;
  search?: string;
  lang: string;
  sort: {
    field: StudentProgressFieldOrderKind;
    order: MoodleOrderKind;
  };
  download: number;
}

export interface StudentProgress {
  id: number;
  email: string;
  fullname: string;
  enrolldate: string;
  lastaccess: string | null;
  progress: number;
  mods: {
    cm_id: number;
    name: string;
    type: string;
    is_completed: boolean;
  }[];
  profile: Array<{ name: string; value: string }>;
}

export interface StudentProgressTableData {
  lastname: string;
  enrolldate: string;
  lastaccess: string | null;
  progress: number;
  griffon_id: string;
  email: string;
  [modKey: `mod_${number}`]: boolean;
}

export interface MoodlePagination {
  total: number;
  page: number;
  per_page: number;
}

export interface GetStudentProgressesResponse {
  section: {
    num: number;
    name: string;
  };
  sections: {
    num: number;
    name: string;
  }[];
  activities: {
    cm_id: number;
    name: string;
    type: string;
  }[];
  users: StudentProgress[];
  courseContent: CourseContentSection[];
  pagination: MoodlePagination;
}

export enum ModTypeEnums {
  GLOSSARY = 'glossary', // глоссарий
  DASSIGN = 'dassign', // задание
  JITSI = 'jitsi', // вебинар
  DQUIZ = 'dquiz', // тест
  DVIDEO = 'dvideo', // видео
  DTEXT = 'dtext', // перезентация
  CHOICE = 'choice', // опрос
  CERTIFICATE = 'customcert',
  PAGE = 'page',
}

export interface GetCoursesProgressRequestParams {
  courses_ids: string[];
}

export interface CourseContentSection {
  id: number;
  name: string;
  section: number;
  summary: string;
  visible: number;
  modules: { id: number; name: string; modname: ModTypeEnums }[];
}

export interface CourseContentSection {
  id: number;
  name: string;
  section: number;
  summary: string;
  visible: number;
  modules: { id: number; name: string; modname: ModTypeEnums }[];
}

export interface StudentProfile {
  id: number;
  username: string;
  firstname: string;
  lastname: string;
  fullname: string;
  email: string;
}

export interface AssignCourseToSelfRequest {
  course_ids: string[];
  channelId: string;
}

export enum AssignEndAction {
  CONTINUE = 'continue',
  CLOSE_ACCESS = 'close_access',
}

export enum AssignFrom {
  MANUAL = 'manual',
  STOREFRONT = 'storefront',
}

export interface AssignCourseToEmployeesRequest {
  channelId: string;
  course_ids: string[];
  users: { email: string; griffon_id: string }[];
  start_date?: string;
  end_date?: string;
  end_action: AssignEndAction;
  assigned_from: AssignFrom;
}

export interface AssignCourseToEmployeesUpdateRequest {
  channel_id: string;
  course_id?: string;
  employee_ids: string[];
  start_date?: string;
  end_date?: string;
  end_action: AssignEndAction;
}

export interface AssignCourseToUsersRequest {
  channelId: string;
  course_ids: string[];
  users: {
    email: string;
    griffon_id: string;
  }[];
  start_date: string;
  end_date: string;
  end_action: AssignEndAction;
  assigned_from: AssignFrom;
}

export interface AssignRequestPayload {
  secret_key: string;
  holding_id: string;
  workspace_id: string;
  users: AssignUsers[];
  assign_info: {
    assigned_from: string;
    end_action: string;
    end_date: string;
    start_date: string;
  };
}

export interface AssignUsers {
  channel_id: string;
  courses: {
    id: string;
    name: string;
    product_code: string;
  }[];
  email: string;
  griffon_id: string;
}

export interface AlreadyAssigned {
  course_id: string;
  course_status: string;
  griffon_id: string;
}

export interface AlreadyAssignedRecord {
  griffon_id: string;
  course_id: string;
  username: string;
  coursename: string;
}

export interface AssignResponse {
  assigned: true;
  already_assigned?: AlreadyAssigned[];
}

export type GetMyInvitedCoursesRequest = {
  page: number;
  size: number;
  filter_by?: {
    status: string | undefined;
    channel_id: string;
  };
  user_id: string;
};

export enum InvoiceStatusTypes {
  UNPAID = 'UNPAID',
  PAST_DUE = 'PAST_DUE',
  PAID = 'PAID',
  OPEN = 'OPEN',
  CANCELLED = 'CANCELLED',
}

export enum SubscriptionStatusTypes {
  UNPAID = 'UNPAID',
  PAST_DUE = 'PAST_DUE',
  PAID = 'PAID',
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
}

export interface CourseDetailsResponseDto {
  id: number;
  progress: number;
  duration: number;
  completed_activities: number;
  total_activities: number;
  code: string;
  displayname: string;
  startdate: string;
  enddate: string;
  fullname: string;
  shortname: string;
  summary: string;
  coursetype: CourseType;
  prices?: Price[];
  customfields: CourseCustomField[];
  mentor: CourseMentor[];
  sections: CourseSection[];
  skills?: Skill[];
  bonus_courses: number[] | null;
  subscripton_startdate?: string;
  subscripton_enddate?: string;
  last_invoice_status?: InvoiceStatusTypes;
  subscription_status?: SubscriptionStatusTypes;
  pay_before?: string;
  total_invoices?: number;
  invoice_number?: number;
  next_payment_invoice?: string;
  trailer_cover?: string;
  bySubscription?: boolean;
}

export type OneCourseResponseDto = {
  courses: CourseDetailsResponseDto[];
};

export type EmployeeDto = {
  all_courses: number;
  course_completed: number;
  department: string;
  email: string;
  employeeFullName: string;
  employeeFullNameRu: string;
  employeeId: string;
  in_progress: number;
  photo: string;
  position: string;
};

export type EmployeeContentStats = {
  assign_date_time: string;
  content_id: string;
  content_title: string;
  status: string;
  enroll_date_time?: string;
  progress?: number;
  complete_date_time?: string;
};

export type GetEmployeesResponse = { employees: EmployeeDto[] };

export type GetOneEmployeeResponse = {
  employees: EmployeeDto;
  employee_content_stats: EmployeeContentStats[] | null;
};

export type EmployeeSort = {
  field: keyof EmployeeAnalyticsTable;
  sort_criteria: 'asc' | 'desc';
};

export type AnalyticsFilterType = {
  departments?: string[];
  positions?: string[];
  assign_type?: AssignType;
  employee_status?: EmployeeAnalyticsStatus[];
  date?: {
    from: string;
    to: string;
  };
};

export type EmployeeFilterType = Omit<
  AnalyticsFilterType,
  'employee_status'
> & {
  channel_id: string;
  employee_status?: EmployeeAnalyticsStatus;
};

export type GetEmployeesRequest = {
  workspace_id: string;
  search?: string;
  page: string;
  size: string;
  sort_by: EmployeeSort;
  filter_by?: EmployeeFilterType;
};

export type GetEmployeesDashboard = {
  workspace_id: string;
  search?: string;
  filter_by?: Omit<AnalyticsFilterType, 'employee_status'> & {
    employee_status?: EmployeeAnalyticsStatus;
  };
};

export type EmployeeFilterForm = {
  departments?: DirectoryEntityDTO[];
  positions?: DirectoryEntityDTO[];
};

export enum AssignType {
  MANUAL = 'manual',
  SHOWCASE = 'storefront',
}

export type GetEmployeesAnalyticsResponse = {
  Employees: EmployeeAnalyticsDto[];
};

export type GetOneEmployeeAnalyticsResponse = {
  Employee: EmployeeAnalyticsDto;
  Assigns: CourseAssignInfo[];
};

export type DashboardInfoType = {
  completed: number;
  in_progress: number;
  not_started: number;
  overdue: number;
  total: number;
};

export type GetEmployeesDashboardResponse = {
  dashboard: {
    total_users: number;
    courses: DashboardInfoType;
    completion_rate: number;
  };
};

export type GetCoursesDashboardResponse = {
  dashboard: {
    total_courses: number;
    users: DashboardInfoType;
    completion_rate: number;
  };
};

export enum EmployeeAnalyticsStatus {
  ACTIVE = 'active',
  DEACTIVATED = 'terminated',
}

export type EmployeeAnalyticsDto = {
  all_courses: number;
  assigns: CourseAssignInfo[];
  course_completed: number;
  course_not_started: number;
  course_overdue: number;
  department: string;
  email: string;
  employeeFullName: string;
  employeeFullNameRu: string;
  employeeId: string;
  in_progress: number;
  photo: string;
  position: string;
  progress: number;
  employee_status: EmployeeAnalyticsStatus;
};

export type EmployeeAnalyticsTable = {
  id: string;
  name: string;
  photo: string;
  progress: number;
  all_courses: string;
  in_progress: string;
  completed: string;
  not_started: string;
  overdue: string;
  department: string;
  position: string;
  employee_status: 'active' | 'deactivated';
  dto: EmployeeAnalyticsDto;
};
export type CourseAssignInfo = {
  assigned_from: AssignType;
  course_id: string;
  course_name: string;
  course_status: string;
  created_at: string;
  end_action: string;
  end_date: string;
  progress: number;
  start_date: string;
};

export type GetOneEmployeeRequest = {
  workspace_id: string;
  id: string;
};

export interface MentorCourseDetails {
  id: number;
  fullname: string;
  summary: string;
  customfields: CourseCustomField[];
  price: Price | null;
}

export enum CourseStatuses {
  DRAFT = 1,
  ON_CHECK = 2,
  APPROVED = 3,
  PUBLISHED = 4,
  HIDDEN = 5,
  ACTIVATED = 6,
  ARCHIVED = 7,
}

export interface ChangeAccessRequest {
  courseIds: number[];
  visible_workspace_ids: string;
  visibility: 'open' | 'hidden';
}

export interface CreateCourseRequest {
  courses: CreateCourseRequestItem[];
}

export enum CourseFormats {
  TOPICS = 'topics',
  FLEX_SECTIONS = 'dflexsections',
}

export interface CreateCourseRequestItem {
  fullname: string;
  shortname: string;
  categoryid: number;
  customfields?: Partial<CourseCustomField>[];
  visible?: number;
  summaryformat?: number;
  format?: CourseFormats;
}

export interface UpdateCourseRequest {
  courses: UpdateCourseRequestItem[];
}

export interface UpdateCourseRequestItem {
  fullname?: string;
  shortname?: string;
  categoryid?: number;
  idnumber?: number;
  summary?: string;
  summaryformat?: number;
  format?: CourseFormats;
  showgrades?: 1 | 0;
  startdate?: number;
  enddate?: number;
  coursetype?: string;
  customfields?: Partial<CourseCustomField>[];
  lang?: string;
  visible?: number;
  id?: number;
}

export interface CourseInfo {
  id: number;
  fullName: string;
  paymentStatus?: string;
  prices: Price[];
  bySubscription: boolean;
  product_subscription_ids: string[];
  invite_status: CourseInviteStatus;
  // custom fields
  language: string;
  course_card_image: string;
  course_logo: string;
  course_cover: string;
  level: string;
  status: string;
  duration: number;
  isPackage: boolean;
  // channel info
  channel: {
    id: number;
    name: string;
    logo: string;
  } | null;
  coursetype: CourseType;
  total_activities: number;
  total_sections: number;
  enddate: number | string; // profession
  startdate: number; // profession
  salary: string[]; // profession
  information: string; // profession
  bonus_courses: number[] | null; // profession
  bonus_courses_by_acl: number[] | null; // profession
  course_cardtext: string;
  forWhoTitles: string[];
  forWhoDescriptions: string[];
  isSellable?: boolean;
  mentors?: CourseMentor[]; // custom
  sections: CourseSection[];
  skills: Skill[];
  summary: string;
  video?: string;
  learn: string[]; // express course
  course_prerequisites: string[]; // express course
  trailer_cover?: string | null;
  accessPeriod?: number | null;
  visible_workspace_ids: string[];
  workspace_id?: string | null;
  holding_id?: string | null;
}

export type CourseInfoWithLMS = CourseInfo & {
  completed_activities: number;
  progress: number;
  total_sections: number;
};

export enum AssignmentModStatus {
  CHECKED = 'checked',
  SUBMITTED = 'submitted',
}

export enum AssignmentSubmissionOrderField {
  DATE = 'date',
  SECTION_TITLE = 'section_title',
  MOD_TITLE = 'mod_title',
  NAME = 'name',
}

export interface GetAssignmentSubmissionsParams {
  type?: AssignmentModStatus;
  moduleIds?: string;
  sectionIds?: string;
  startDate?: string;
  endDate?: string;
  course_id: number;
  sort: {
    field: AssignmentSubmissionOrderField;
    order: MoodleOrderKind;
  };
  search?: string;
  page: number;
  per_page: number;
  groupIds: string[];
}

export interface AssignmentMod {
  id: number;
  title: string;
  type: string;
}

export interface AssignmentUser {
  name: string;
  surname: string;
  id: number;
  email: string;
}

export interface AssignmentSection {
  id: string;
  title: string;
}

export interface GetSubmissionsTableSubmission {
  mod: AssignmentMod;
  user: AssignmentUser;
  section: AssignmentSection;
  submission_date: string;
  grade: string | null;
  id: string;
}

export interface SubmissonsTableData {
  id: string;
  name: string;
  photo: string;
  date: string;
  section_title: string;
  mod_title: string;
  mod_id: number;
  user_id: number;
}

export interface GetAssignmentSubmissionsResponse {
  submissions: GetSubmissionsTableSubmission[];
  pagination: MoodlePagination;
}

export enum HomeworkModStatus {
  CHECKED = 'checked',
  SUBMITTED = 'submitted',
}

export type GetCoursesStatisticsRequest = {
  workspace_id: string;
  page: string;
  size: string;
  filter_by: Omit<AnalyticsFilterType, 'employee_status'> & {
    employee_status?: EmployeeAnalyticsStatus;
  };
  content_ids?: string[];
};

export type GetCoursesDashboardRequest = {
  workspace_id: string;
  filter_by: Omit<AnalyticsFilterType, 'employee_status'> & {
    employee_status?: EmployeeAnalyticsStatus;
  };
  content_ids?: string[];
};

export type CoursesStatisticsResponse = {
  access_type: string;
  channel_id: number;
  id: string;
  title: string;
  users: {
    completed: number;
    in_progress: number;
    not_started: number;
    overdue: number;
    total: number;
  };
};

export type CoursesStatisticsTable = {
  access_type: string;
  channel_id: number;
  id: string;
  title: string;
  completed: number;
  in_progress: number;
  not_started: number;
  overdue: number;
  total: number;
  progress: number;
};

export type GetCoursesStatisticsResponse = {
  courses: CoursesStatisticsResponse[];
};

export enum CoursesProgressOrderKind {
  TITLE = 'title',
  ASSIGN_TYPE = 'assign_type',
  PROGRESS = 'progress',
  TOTAL = 'total',
  IN_PROGRESS = 'in_progress',
  NOT_STARTED = 'not_started',
  COMPLETED = 'completed',
  OVERDUE = 'overdue',
}

export enum StatusEnum {
  PROGRESS = 'in_progress',
  COMPLETED = 'completed',
  OVERDUE = 'overdue',
  NOT_STARTED = 'not_started',
  FINISHED = 'finished',
  ACTIVE = 'active',
}

export type MyAssignedCoursesResponse = {
  assigned_from: string;
  course_id: string;
  course_name: string;
  end_action: AssignEndAction;
  end_date: string;
  progress: number;
  start_date: string;
  status: StatusEnum;
};

export type MyAssignedCoursesInfo = {
  id: string;
  fullName: string;
  endDate: string;
  endAction: AssignEndAction;
  assignedForm: string;
  progress: number;
  status: StatusEnum;
  completed_activities: number;
  total_sections: number;
  total_activities: number;
  courseImage: string;
  startDate: string;
};

export type GetEmployeeAnalyticsReportRequest = {
  filter_by: {
    assign_type?: string;
    channel_id?: string;
    date?: {
      from: string;
      to: string;
    };
    departments?: string[];
    positions?: string[];
    employee_status?: EmployeeAnalyticsStatus;
  };
  language: string;
  search?: string;
};

export type UploadToCmsResponse = { url: string };

export type GetCourseContentResponse = {
  id: number;
  fullname: string;
  shortname?: string;
  displayname?: string;
  coursetype: string;
  startdate: string;
  enddate: string;
  summary: string;
  customfields: Record<string, CourseCustomField>;
  sections: { title: string; description: string }[];
  mentor: CourseMentor[];
  skills: { id: string; name: string };
  bonus_courses: number[];
  total_activities: number;
};

export interface CourseLmsDetails {
  sections: LmsSection[];
  fullname: string;
  grade?: {
    completion: number;
  };
  customfields: Record<string, CourseCustomField>;
  isenrolled: boolean;
}

export interface CourseLmsDetailsResponseDto {
  course: CourseLmsDetails;
}
export type LmsSection = {
  availability: boolean;
  coursetype: string;
  mod: Mod;
  name: string;
  section: string;
  sectionid: string;
  summary: string;
  theme: boolean;
  status?: StatusText;
  completionPercentage?: number;
  firstSection?: boolean;
};

export type ModInner = {
  complition: boolean;
  courseid: string;
  coursetype: string;
  id: string;
  mod: string;
  name: string;
  section: string;
};

export type Mod = {
  [key: string]: ModInner;
};

export interface CourseContentSection {
  id: number;
  name: string;
  section: number;
  summary: string;
  visible: number;
  modules: CourseContentModule[];
}

export interface CourseContentModule {
  id: number;
  name: string;
  modname: ModTypeEnums;
}
