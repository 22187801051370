export const environment = {
  production: true,
  envName: 'prod',
  griffonApiRoot: 'https://account.5q.kz/api/v1',
  griffonLmsApiRoot: 'https://account.5q.kz/api/v1',
  griffonBucketId: 'bb4fc077-3c81-4419-a4d8-c2466aa12b42',
  griffonLmsBucketId: 'bb4fc077-3c81-4419-a4d8-c2466aa12b42',
  lmsFront: 'https://5qbe.kz',
  commsBaseApi: 'https://dms-comms.dar.io/api',
  hcmsBaseApi: 'https://dms-hcms.dar.io/api',
  dmsBaseApi: 'https://dms.dar.io/api/v1',
  api5qRoot: 'https://api.5qbe.kz/api/v1',
  bffRoot: 'https://dms-lms.dar.io',
  useEmployeesMock: false,
  localeRegion: 'KZ',
};
