import { lazy, Suspense } from 'react';
import { Navigate, createBrowserRouter } from 'react-router-dom';
import { Spinner } from '@dartech/dms-ui';
import { ROUTER_PREFIX } from '../constants';
import { BreadcrumbContextProvider } from '../contexts/breadcrumb.context';
import { ToastContextProvider } from '../contexts/toast.context';

const LmsLayout = lazy(() =>
  import('../layout/lms/Lms').then((m) => ({
    default: m.LmsLayout,
  }))
);
const MainLayout = lazy(() =>
  import('../layout/main/Main').then((m) => ({
    default: m.MainLayout,
  }))
);
const CourseDetailsLayout = lazy(() =>
  import('../layout/courseDetails/CourseDetailsLayout').then((m) => ({
    default: m.CourseDetailsLayout,
  }))
);
const LmsAuth = lazy(() =>
  import('./pages/auth/LmsAuthPage').then((m) => ({
    default: m.LmsAuth,
  }))
);
const EmployeesListPage = lazy(() =>
  import('./pages/employeesList/EmployeesListPage').then((m) => ({
    default: m.EmployeesListPage,
  }))
);
const ProgressPage = lazy(() =>
  import('./pages/progress/ProgressPage').then((m) => ({
    default: m.ProgressPage,
  }))
);
const Upgrade = lazy(() =>
  import('./pages/upgrade/UpgradePage').then((m) => ({
    default: m.UpgradePage,
  }))
);

export const router = createBrowserRouter([
  {
    id: 'root',
    path: `/${ROUTER_PREFIX}`,
    element: (
      <BreadcrumbContextProvider>
        <ToastContextProvider>
          <Suspense fallback={<Spinner variant="page" />}>
            <MainLayout />
          </Suspense>
        </ToastContextProvider>
      </BreadcrumbContextProvider>
    ),
    children: [
      {
        path: 'auth',
        element: <LmsAuth />,
      },
      {
        path: 'upgrade',
        element: <Upgrade />,
      },
      {
        path: ':tab?',
        element: <LmsLayout />,
        children: [],
      },
      {
        path: 'analytics/progress',
        element: <ProgressPage />,
      },
      {
        path: 'analytics/employees',
        element: <EmployeesListPage />,
      },
      {
        path: 'analytics/progress/course/:courseId/:tab?',
        element: <CourseDetailsLayout />,
        children: [],
      },
      {
        path: 'analytics/progress/course/:courseId',
        index: true,
        element: <Navigate to="assigns" replace />,
      },
    ],
  },
]);
